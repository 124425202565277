
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import PageAnalytics from '../../../atomic/organisms/Page-Analytics/Page-Analyitics.vue';
import Table from '../../../atomic/organisms/Table/Table.vue';
import NavTabs from '../../../atomic/atoms/Nav-Tabs/Nav-Tabs.vue';
import PageControls from '../../../atomic/organisms/Page-Controls/Page-Controls.vue';
import { ColumnDefinition, SortDefinition } from '../../../utilities/Types/table.types';
import { Tabs } from '../../../utilities/Types/navigation.types';
import { IDropdownOption } from '../../../utilities/Interfaces/form.interfaces';

export default defineComponent({
  components: {
    'arc-page-analytics': PageAnalytics,
    'arc-table': Table,
    'arc-nav-tabs': NavTabs,
    'arc-page-controls': PageControls,
  },
  async mounted(): Promise<void> {
    const response = await this.fetchMallChangeRequests({
      page: 1,
      limit: 10000,
      search: this.searchFilter,
      sortKey: 'changeRequestId',
      sortDirection: this.sortDefinition.direction,
    });
    this.changeRequests = response?.data;
    this.total = response?.total;
  },
  watch: {
    mallChangeRequests: {
      deep: true,
      async handler() {
        this.changeRequests = this.mallChangeRequests;
        this.all = 0;
        this.approved = 0;
        this.pending = 0;
        this.declined = 0;
        this.changeRequests?.forEach((request: any) => {
          this.all++;
          if (request?.statusDesc === 'Pending') {
            this.pending++;
          }
          if (request?.statusDesc === 'Approved') {
            this.approved++;
          }
          if (request?.statusDesc === 'Declined') {
            this.declined++;
          }
        });
      },
    },
    resetPagination: {
      deep: true,
      async handler() {
        this.showValue = { description: '25', value: 25 };
        this.pageValue = { description: '1', value: 1 };
      },
    },
    loadingMallChangeRequests: {
      handler() {
        this.loading = this.loadingMallChangeRequests;
      },
    },
  },
  computed: {
    ...mapGetters([
      'allCommunicationReports',
      'malls',
      'mallTypes',
      'loadingMalls',
      'loadingMallChangeRequests',
      'user',
      'resetPagination',
      'mallChangeRequests',
    ]),
    isLoading(): boolean {
      return this.loading || this.loadingMallChangeRequests;
    },
    columnDefinitions(): ColumnDefinition[] {
      if (this.currentFilter === 'approved') {
        return [
          {
            type: 'text',
            columnName: 'changeRequestId',
            displayName: 'ID',
            // sortable: true,
          },
          {
            type: 'text',
            columnName: 'mallName', // reformating on entry
            displayName: 'Mall Name',
            // sortable: true,
          },
          {
            type: 'contact',
            columnName: 'requestedBy', // reformating on entry
            displayName: 'Requested By',
            // sortable: true,
          },
          {
            type: 'date',
            columnName: 'createdAt',
            displayName: 'Requested On',
            // sortable: true,
          },
          {
            type: 'text',
            columnName: 'actionedBy', // reformating on entry
            displayName: 'Actioned By',
            // sortable: true,
          },
          {
            type: 'date',
            columnName: 'updatedAt',
            displayName: 'Actioned On',
            // sortable: true,
          },
        ];
      }
      if (this.currentFilter === 'pending') {
        return [
          {
            type: 'text',
            columnName: 'changeRequestId',
            displayName: 'ID',
            // sortable: true,
          },
          {
            type: 'text',
            columnName: 'mallName', // reformating on entry
            displayName: 'Mall Name',
            // sortable: true,
          },
          {
            type: 'contact',
            columnName: 'requestedBy', // reformating on entry
            displayName: 'Requested By',
            // sortable: true,
          },
          {
            type: 'date',
            columnName: 'createdAt',
            displayName: 'Requested On',
            // sortable: true,
          },
        ];
      }
      if (this.currentFilter === 'declined') {
        return [
          {
            type: 'text',
            columnName: 'changeRequestId',
            displayName: 'ID',
            // sortable: true,
          },
          {
            type: 'text',
            columnName: 'mallName', // reformating on entry
            displayName: 'Mall Name',
            // sortable: true,
          },
          {
            type: 'contact',
            columnName: 'requestedBy', // reformating on entry
            displayName: 'Requested By',
            // sortable: true,
          },
          {
            type: 'date',
            columnName: 'createdAt',
            displayName: 'Requested On',
            // sortable: true,
          },
          {
            type: 'text',
            columnName: 'reasonForDeclineDescription',
            displayName: 'Reason for Decline',
            // sortable: true,
          },
          {
            type: 'text',
            columnName: 'actionedBy', // reformating on entry
            displayName: 'Actioned By',
            // sortable: true,
          },
          {
            type: 'date',
            columnName: 'updatedAt',
            displayName: 'Actioned On',
            // sortable: true,
          },
        ];
      }
      return [
        {
          type: 'text',
          columnName: 'changeRequestId',
          displayName: 'ID',
          // sortable: true,
        },
        {
          type: 'text',
          columnName: 'mallName', // reformating on entry
          displayName: 'Mall Name',
          // sortable: true,
        },
        {
          type: 'contact',
          columnName: 'requestedBy', // reformating on entry
          displayName: 'Requested By',
          // sortable: true,
        },
        {
          type: 'date',
          columnName: 'createdAt',
          displayName: 'Requested On',
          // sortable: true,
        },
        {
          type: 'text',
          columnName: 'statusDesc', // reformating on entry
          displayName: 'Status',
          // sortable: true,
        },
        {
          type: 'text',
          columnName: 'reasonForDeclineDescription',
          displayName: 'Reason for Decline',
          // sortable: true,
        },
        {
          type: 'text',
          columnName: 'actionedBy', // reformating on entry
          displayName: 'Actioned By',
          // sortable: true,
        },
        {
          type: 'date',
          columnName: 'updatedAt',
          displayName: 'Actioned On',
          // sortable: true,
        },
      ];
    },
    tabs(): Tabs {
      return [
        {
          title: 'MALLS',
          route: 'view',
          activeRouteCheck: '/malls/view',
        },
        {
          title: `CHANGE REQUESTS [${this.pending || 0}]`,
          route: 'change-requests',
          activeRouteCheck: '/malls/change-requests',
        },
      ];
    },
    tabs2(): Tabs {
      return [
        {
          title: `ALL (${this.all || 0})`,
          activeTabFlag: 'all',
        },
        {
          title: `APPROVED (${this.approved || 0})`,
          activeTabFlag: 'approved',
        },
        {
          title: `PENDING (${this.pending || 0})`,
          activeTabFlag: 'pending',
        },
        {
          title: `DECLINED (${this.declined || 0})`,
          activeTabFlag: 'declined',
        },
      ];
    },
    tableData(): unknown {
      let filteredData = [];
      if (this.currentFilter === 'all') {
        filteredData = this.changeRequests;
      }
      if (this.currentFilter === 'approved') {
        filteredData = this.changeRequests?.filter((request: any) => request?.statusDesc === 'Approved');
      }
      if (this.currentFilter === 'pending') {
        filteredData = this.changeRequests?.filter((request: any) => request?.statusDesc === 'Pending');
      }
      if (this.currentFilter === 'declined') {
        filteredData = this.changeRequests?.filter((request: any) => request?.statusDesc === 'Declined');
      }
      // searching
      if (this.searchFilter !== '') {
        let searchResults: any[] = [];
        filteredData.forEach((element: any) => {
          let matchFound = false;
          if (typeof Object.values(element) === 'string') {
            if (Object.values(element).includes(this.searchFilter)) {
              matchFound = true;
            }
          }
          if (typeof Object.values(element) === 'number') {
            if (
              Object.values(element)
                .toString()
                .includes(this.searchFilter)
            ) {
              matchFound = true;
            }
          }
          if (typeof Object.values(element) === 'object' && Object.values(element)) {
            Object.values(element)
              ?.filter((value) => value)
              ?.forEach((nestedElement: any) => {
                if (typeof nestedElement === 'string') {
                  if (nestedElement.toLowerCase().includes(this.searchFilter.toLowerCase())) {
                    matchFound = true;
                  }
                }
                if (typeof nestedElement === 'number') {
                  if (nestedElement.toString().includes(this.searchFilter)) {
                    matchFound = true;
                  }
                }
                if (typeof nestedElement === 'object' && nestedElement) {
                  Object.values(nestedElement)
                    ?.filter((value) => value)
                    ?.forEach((nestedElementL2: any) => {
                      if (typeof nestedElementL2 === 'string') {
                        if (nestedElementL2.toLowerCase().includes(this.searchFilter.toLowerCase())) {
                          matchFound = true;
                        }
                      }
                      if (typeof nestedElementL2 === 'number') {
                        if (nestedElementL2.toString().includes(this.searchFilter)) {
                          matchFound = true;
                        }
                      }
                      if (typeof nestedElementL2 === 'object' && nestedElementL2) {
                        Object.values(nestedElementL2)
                          ?.filter((value) => value)
                          ?.forEach((nestedElementL3: any) => {
                            if (typeof nestedElementL3 === 'string') {
                              if (nestedElementL3.toLowerCase().includes(this.searchFilter.toLowerCase())) {
                                matchFound = true;
                              }
                            }
                            if (typeof nestedElementL3 === 'number') {
                              if (nestedElementL3.toString().includes(this.searchFilter)) {
                                matchFound = true;
                              }
                            }
                          });
                      }
                    });
                }
              });
          }
          if (matchFound) {
            searchResults.push(element);
          }
        });
        filteredData = searchResults;
      }
      // sorting
      if (filteredData && filteredData?.length > 0) {
        if (this.sortDefinition.direction === 'ASC') {
          if (this.sortDefinition.type === 'text') {
            filteredData.sort((a: any, b: any) =>
              a[this.sortDefinition.column] > b[this.sortDefinition.column]
                ? 1
                : b[this.sortDefinition.column] > a[this.sortDefinition.column]
                ? -1
                : 0,
            );
          }
          if (this.sortDefinition.type === 'date') {
            filteredData.sort((a: any, b: any) =>
              new Date(a[this.sortDefinition.column]) > new Date(b[this.sortDefinition.column])
                ? 1
                : new Date(b[this.sortDefinition.column]) > new Date(a[this.sortDefinition.column])
                ? -1
                : 0,
            );
          }
          if (this.sortDefinition.type === 'approval') {
            filteredData.sort((a: any, b: any) =>
              a[this.sortDefinition.column].percentage > b[this.sortDefinition.column].percentage
                ? 1
                : b[this.sortDefinition.column].percentage > a[this.sortDefinition.column].percentage
                ? -1
                : 0,
            );
          }
        } else {
          if (this.sortDefinition.type === 'text') {
            filteredData.sort((a: any, b: any) =>
              a[this.sortDefinition.column] < b[this.sortDefinition.column]
                ? 1
                : b[this.sortDefinition.column] < a[this.sortDefinition.column]
                ? -1
                : 0,
            );
          }
          if (this.sortDefinition.type === 'date') {
            filteredData.sort((a: any, b: any) =>
              new Date(a[this.sortDefinition.column]) < new Date(b[this.sortDefinition.column])
                ? 1
                : new Date(b[this.sortDefinition.column]) < new Date(a[this.sortDefinition.column])
                ? -1
                : 0,
            );
          }
          if (this.sortDefinition.type === 'approval') {
            filteredData.sort((a: any, b: any) =>
              a[this.sortDefinition.column].percentage < b[this.sortDefinition.column].percentage
                ? 1
                : b[this.sortDefinition.column].percentage < a[this.sortDefinition.column].percentage
                ? -1
                : 0,
            );
          }
        }
      }
      return filteredData;
    },
    tableOptions(): any {
      return {
        clickable: true,
      };
    },
    pageOptions(): IDropdownOption[] {
      let total: any = this.total;
      let showValue: any = this.showValue.value;
      let options = [];
      let numberOfPages = Math.ceil(total / showValue);
      for (let i = 0; i < numberOfPages; i++) {
        options.push({ description: `${i + 1}`, value: i + 1 });
      }
      return options;
    },
    totalPages(): number {
      let total: any = this.total;
      let showValue: any = this.showValue.value;
      return Math.ceil(total / showValue);
    },
  },

  data(): {
    statusFilterValue: IDropdownOption;
    statusFilterOptions: IDropdownOption[];
    searchFilter: string;
    showValue: IDropdownOption;
    showOptions: IDropdownOption[];
    pageValue: IDropdownOption;
    allTableData: any;
    sortDefinition: SortDefinition;
    loading: boolean;
    total: number;
    totalMalls: number;
    changeRequests: any;
    all: number;
    pending: number;
    approved: number;
    declined: number;
    currentFilter: string;
  } {
    return {
      statusFilterValue: { description: 'All', value: 'all' },
      statusFilterOptions: [
        { description: 'All', value: 'all' },
        { description: 'Active', value: 'active' },
        { description: 'Upcoming', value: 'upcoming' },
        { description: 'Expired', value: 'expired' },
        { description: 'Draft', value: 'draft' },
      ],
      searchFilter: '',
      showValue: { description: '25', value: 25 },
      showOptions: [
        { description: '10', value: 10 },
        { description: '25', value: 25 },
        { description: '50', value: 50 },
        { description: '100', value: 100 },
      ],
      pageValue: { description: '1', value: 1 },
      allTableData: [],
      sortDefinition: { column: 'id', type: 'text', direction: 'DESC' },
      loading: true,
      total: 0,
      totalMalls: 0,
      changeRequests: null,
      all: 0,
      pending: 0,
      approved: 0,
      declined: 0,
      currentFilter: 'pending',
    };
  },

  methods: {
    ...mapActions(['openModal', 'updateModalType', 'updateModalComponent', 'updateModalData', 'fetchMalls', 'fetchMallChangeRequests']),
    startLoading(): void {
      this.loading = true;
    },
    finishLoading(): void {
      this.loading = false;
    },
    resetPageValue(): void {
      this.pageValue = { description: '1', value: 1 };
    },
    updateAllTableData(data: any): void {
      this.allTableData = data;
    },
    async sort(payload: SortDefinition) {
      this.resetPageValue();
      this.sortDefinition = payload;
    },
    async updateShow(filter: IDropdownOption) {
      this.resetPageValue();
      this.showValue = filter;
    },
    async updatePage(filter: IDropdownOption) {
      this.pageValue = filter;
    },
    async previousPage() {
      let pageValue: any = this.pageValue;
      this.pageValue = {
        description: `${pageValue.value - 1}`,
        value: pageValue.value - 1,
      };
    },
    async nextPage() {
      let pageValue: any = this.pageValue;
      this.pageValue = {
        description: `${pageValue.value + 1}`,
        value: pageValue.value + 1,
      };
    },
    async search(searchFilter: string): Promise<void> {
      this.searchFilter = searchFilter;
      this.resetPageValue();
    },
    create(): void {
      this.updateModalComponent('mall');
      this.updateModalType('large');
      this.openModal();
    },
    reports(): void {
      this.$router.push('/reports');
    },
    export(): void {
      const link = document.createElement('a');
      link.target = '_target';
      link.href = `${process.env.VUE_APP_URI}/malls/export`;
      link.click();
    },
    async rowClicked(row: any): Promise<void> {
      try {
        this.updateModalComponent('mallChangeRequest');
        this.updateModalType('large');
        this.updateModalData({ row: row, mode: 'approve' });
        this.openModal();
      } catch (err) {
        this.$notify({
          title: 'ERROR',
          text: `Unable to fetch Mall details`,
          type: 'error',
          duration: 5000,
        });
      }
    },
  },
});
